import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Header {
  constructor() {
    if (document.querySelector(".l-header")) {
      this.scrollHeader();
    }
  }

  scrollHeader() {
    window.scrollTo(0, 1);

    const header = document.querySelector(".l-header");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 200 && !header.classList.contains("-fixed")) {
        header.classList.add("-fixed");
      } else if (window.scrollY < 10) {
        header.classList.remove("-fixed");
      }
    });
  }
}

export default Header;
