import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Kv1 {
  constructor() {
    if (document.querySelector(".p-kv1")) {
      this.hideLogo();
      this.scrollLogo();
    }
  }

  hideLogo() {
    const logo = document.querySelector(".p-kv1__logo1");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 200 && !logo.classList.contains("-hide")) {
        logo.classList.add("-hide");
      } else if (window.scrollY < 100) {
        logo.classList.remove("-hide");
      }
    });
  }

  scrollLogo() {
    const logo = document.querySelector(".p-kv1__logo1");

    function updateLogoPosition() {
      const scrollAmount = window.scrollY;
      const translateY = scrollAmount / 4;
      logo.style.transform = `translateY(-${translateY}px)`;
    }

    window.addEventListener("scroll", updateLogoPosition);
  }
}

export default Kv1;
