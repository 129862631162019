import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Block5 {
  constructor() {
    if (document.querySelector(".p-block5")) {
      this.randomNumber();
    }
  }

  randomNumber() {
    const block = document.querySelector(".p-block5");
    const text = block.querySelectorAll(".p-block5__list1__item1__text2 span");

    gsap.from(block, {
      opacity: 0,
      scrollTrigger: {
        trigger: block,
        start: "top+=200 bottom",
        once: true, // 一度だけ実行する
        onEnter: () => {
          text.forEach((el) => {
            const digitCount = getDigitCount(el);
            repeatReplaceText(el, digitCount, 1000, 20);
          });
        },
      },
    });

    // 取得した数値と桁数を取得
    function getDigitCount(el) {
      // 1. #numberの値を取得
      const inputValue = el.textContent;

      // 2. 取得した値の桁数を取得
      const digitCount = inputValue.toString().length;
      return digitCount;
    }

    // 渡された数値の範囲内でランダムな数値を作成
    function generateRandomNumberWithSameDigits(inputNumber) {
      if (typeof inputNumber !== "number" || inputNumber <= 0) {
        throw new Error("Invalid input. Please provide a positive number.");
      }

      // 渡された数字と同じ桁数の最小値と最大値を計算
      const minNumber = Math.pow(10, inputNumber - 1);
      const maxNumber = Math.pow(10, inputNumber) - 1;

      // ランダムな数値を生成
      const randomNumber = Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
      return randomNumber;
    }

    // 要素のテキストを差し替える
    function replaceText(el, newText) {
      el.textContent = newText;
    }

    function repeatReplaceText(el, digitCount, duration, interval) {
      let elapsed = 0;
      const number = el.textContent;

      function updateText() {
        replaceText(el, generateRandomNumberWithSameDigits(digitCount));

        elapsed += interval;
        if (elapsed < duration) {
          setTimeout(updateText, interval);
        } else {
          replaceText(el, number);
        }
      }

      setTimeout(updateText, interval);
    }
  }
}

export default Block5;
