import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class TopAnimation {
  constructor() {
    if (document.querySelector("body.home")) {
      this.scrollAnimation();
    }
  }

  scrollAnimation() {
    const musk1 = [".p-block2__main1"];

    musk1.forEach(function (el, index) {
      gsap.from(el, {
        duration: 1,
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        ease: "Expo.easeOut",
        scrollTrigger: {
          trigger: el,
          start: "top+=200 bottom",
        },
      });
    });

    const down1 = [
      ".p-block2__image1 img",
      ".p-block3__list1 li",
      ".p-block4__title1",
      ".p-block4__list1 li",
      ".p-block5__list1 li",
      ".p-block6__swiper1",
      ".p-cta1__inner1 .p-cta1__unit1",
      ".p-block6__title1",
    ];

    down1.forEach(function (el, index) {
      gsap.from(el, {
        opacity: 0,
        y: -30,
        duration: 1,
        stagger: 0.05,
        ease: "Expo.easeOut",
        scrollTrigger: {
          trigger: el,
          start: "top+=200 bottom",
        },
      });
    });
  }
}

export default TopAnimation;
