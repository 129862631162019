import Swiper from "swiper/bundle";

class Carousel {
  constructor() {
    if (document.querySelector(".p-block1")) {
      this.swiper1 = document.querySelector(".p-block1");
      this.block1Carousel1();
    }
    if (document.querySelector(".p-block6")) {
      this.swiper2 = document.querySelector(".p-block6");
      this.block6Carousel1();
    }
  }

  block1Carousel1() {
    const swiper = new Swiper(this.swiper1.querySelector(".swiper"), {
      speed: 500,
      spaceBetween: 20,
      slidesPerView: 1,
      pagination: {
        el: this.swiper1.querySelector(".swiper-pagination"),
        clickable: true,
      },
      navigation: {
        nextEl: this.swiper1.querySelector(".swiper-button-next"),
        prevEl: this.swiper1.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 48,
          slidesPerView: 3,
        },
        1280: {
          spaceBetween: 48,
          slidesPerView: 4,
        },
      },
    });
  }

  block6Carousel1() {
    const swiper = new Swiper(this.swiper2.querySelector(".swiper"), {
      speed: 500,
      spaceBetween: 20,
      slidesPerView: 1,
      navigation: {
        nextEl: this.swiper2.querySelector(".swiper-button-next"),
        prevEl: this.swiper2.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        768: {
          spaceBetween: 48,
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
        },
      },
    });
  }
}

export default Carousel;
