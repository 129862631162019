import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Loading {
  constructor() {
    if (document.querySelector(".p-loading1")) {
      this.loading();
    }
  }

  loading() {
    const loadingBlock = document.querySelector(".p-loading1");
    const mainLogo = document.querySelector(".p-kv1__logo1");
    const body = document.querySelector("body");

    window.addEventListener("load", () => {
      const timeline1 = gsap.timeline();

      body.classList.remove("-loading");

      timeline1
        .to(loadingBlock, {
          duration: 1,
          clipPath: "polygon(0 0%, 100% 0%, 100% 0%, 0% 0%)",
          ease: "Power4.easeInOut",
        })
        .to(
          mainLogo,
          {
            duration: 1,
            opacity: 1,
            ease: "Power4.easeInOut",
          },
          "-=0.5"
        );
    });
  }
}

export default Loading;
