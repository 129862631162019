import Carousel from "./modules/carousel";
import Header1 from "./modules/header1";
import Loading from "./modules/loading1";
import Kv1 from "./modules/kv1";
import TopAnimation from "./modules/topAnimation";
import Block5 from "./modules/block5";

const init = () => {
  new Loading();
  new Carousel();
  new Header1();
  new Kv1();
  new TopAnimation();
  new Block5();
};

window.addEventListener("DOMContentLoaded", () => {
  init();
});
